<template>
  <div>
    <v-card width="1000" class="mx-auto">
      <v-card-title>
        <span class="headline">
          <b>تعديل زيارة</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-text-field
                  label="اسم المهمة"
                  placeholder="اسم المهمة"
                  v-model="visit.task.name"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="selectedvisitStatus"
                  :items="visitStatus"
                  item-text="item"
                  item-value="value"
                  label="حالة المهمة"
                  :rules="visitStatusRules"
                  persistent-hint
                  return-object
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  label="المستخدم المنشئ"
                  placeholder="المستخدم المنشئ"
                  v-model="creatorId.item"
                  required
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="ResponsibleId"
                  :items="ResponsibleUsers"
                  item-text="item"
                  item-value="value"
                  label="المستخدم  له"
                  :rules="usersRules"
                  persistent-hint
                  return-object
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="visit.startDate"
                            label="تاريخ البداية"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="visit.startDate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            إغلاق
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                          >
                            حفظ
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </template>
              </v-flex>
              <v-flex xs12 sm6>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="date2"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="visit.endDate"
                            label="تاريخ النهاية"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="visit.endDate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            إغلاق
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(date2)"
                          >
                            حفظ
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </template>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  label="ملاحظات المهمة"
                  v-model="visit.notes"
                  placeholder="ملاحظات المهمة"
                  required
                ></v-text-field>
              </v-flex>

              <!--      <v-flex xs12 sm6>
                          <v-switch
                            v-model="repeat"
                            label="التكرار"
                            color="info"
                            :value="true"
                            hide-details
                          ></v-switch>
                        </v-flex>
                         <v-flex xs12 sm6 v-if="repeat">
                            <v-select
                            v-model="selecyedvisitRepetition"
                            :items="visitRepetition"
                            item-text="item"
                            item-value="value"
                            label="تكرار المهمة"
                            :rules="visitRepetitionRules"
                            persistent-hint
                            return-object
                            required
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 v-if="repeat">
                          <v-text-field
                                label="عدد مرات التكرار"
                                placeholder="عدد مرات التكرار"
                                v-model="visit.Reapets"
                                required
                            ></v-text-field>  
                        </v-flex>-->
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="editvisit"
            style="background:#5867dd;color:white;margin-left:5px;"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background:gray;" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      usersRules: [
        v => !!v || "المستخدم مطلوب",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      visitRepetitionRules: [v => v.value != "0" || "الاختيار غير مقبول"],
      visitStatusRules: [
        v => !!v || "حالة المهمة مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      modal: false,
      modal2: false,
      repeat: false,
      id:'',
      users: [],
      ResponsibleUsers: [{ item: "المستخدم الموكلة له المهمة", value: "0" }],
      selectedvisitStatus: 0,
      visitStatus: [
        { value: "0", item: "اختر حالة المهمة" },
        { value: "5", item: "بانتظار المراجعة" },
        { value: "2", item: "قيد التقدم" },
        { value: "3", item: "منجزة" },
        { value: "4", item: "موكلة" }
      ],
      nameRules: [
        v => !!v || "الاسم مطلوب",
        v => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف"
      ],
      selectedvisitTypeRules: [
        v => !!v || "نوع المهمة مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      selectedvisitType: 0,
      selecyedvisitRepetition: 0,
      user: null,
      ResponsibleId: 0,
      creatorId: { value: "0", item: "--" },
      role: "",
      visit: {
        endDate: "",
        idvisit: "",
        name: "",
        notes: "",
        statusId: "",
        startDate: "",
        responsibleId: ""
      }
    };
  },
  methods: {
    editvisit() {
      if (this.$refs.form.validate()) {
        var data = {
          Name: this.visit.task.name,
          ResponsibleId: this.ResponsibleId.value,
          CreatorId: this.creatorId.value,
          StatusId: this.selectedvisitStatus.value,
          Notes: this.visit.notes,
          StartDate: this.visit.startDate,
          EndDate: this.visit.endDate
        };
     
        this.ApiService.put(
          `visit/UpdateVisitInfo/${this.visit.idvisit}?user=${this.id}`,
          data
        )
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            this.$router.push({ name: "visits" });
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    },
    async getUsers() {
      this.users = [];
      this.ResponsibleUsers = [
        { item: "المستخدم الموكلة له المهمة", value: "0" }
      ];
      await this.ApiService.get("auth/listusers")
        .then(res => {
          this.users = res.data;
          this.users.forEach(el => {
            this.ResponsibleUsers.push({
              value: el.user.id,
              item: el.user.fullName
            });
          });
          this.users.forEach(el => {
            if (el.user.id == this.visit.task.creatorId) {
              this.creatorId = {
                value: this.visit.task.creatorId,
                item: el.user.fullName
              };
              // console.log(this.visit.task.creatorId)
            }
            if (el.id == this.visit.task.responsibleId) {
              this.ResponsibleId = {
                value: this.visit.responsibleId,
                item: el.fullName
              };
            }
          });
        })
        .catch(() => {});
      this.setvisitDetails();
    },
    setvisitDetails() {
      if (this.visit.task.statusId == 5) {
        this.selectedvisitStatus = { value: "5", item: "بانتظار المراجعة" };
      } else if (this.visit.task.statusId == 2) {
        this.selectedvisitStatus = { value: "2", item: "قيد التقدم" };
      } else if (this.visit.task.statusId == 3) {
        this.selectedvisitStatus = { value: "3", item: "منجزة" };
      } else if (this.visit.task.statusId == 4) {
        this.selectedvisitStatus = { value: "4", item: "موكلة" };
      }
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.id = JSON.parse(window.localStorage.getItem("id"));
    this.role = window.localStorage.getItem("roles");
    this.visit = this.$route.params.visit;
    const formatYmd = date => date.toISOString().slice(0, 10);
    this.visit.startDate = formatYmd(new Date(this.visit.task.startDate));
    this.visit.endDate = formatYmd(new Date(this.visit.task.endDate));
    this.getUsers();
    if (this.visit == null) {
      this.$router.push({ name: "visits" });
    }
  }
};
</script> 

<style lang="scss"></style>